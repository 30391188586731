<template>
  <div class="sms-opt-in-form">
    <div class="sms-selection-inputs">
      <div class="form-input">
        <b-checkbox v-model="optInSelection.marketingTexts">
          <div>I want to receive marketing text messages (for example, promotions) at the number provided.</div>
        </b-checkbox>
      </div>
      <div class="form-input">
        <b-checkbox v-model="optInSelection.transactionalTexts">
          <div>I want to receive transactional text messages (for example, order status updates) at the number provided.</div>
        </b-checkbox>
      </div>
      <div class="columns">
        <div :class="`form-input column is-${this.mobileInputCols} is-12-mobile`">
          <fieldify label="Mobile number" v-floating-label>
            <cleave class="input is-large" v-model="optInSelection.phoneNumber" :disabled="smsPhoneNumberDisabled" name="phone" :options="phoneOptions" :raw="true" tabindex="0"></cleave>
          </fieldify>
        </div>
      </div>
      <div>
        Enter your phone number to sign up for Thorne SMS and begin receiving marketing and/or transactional messages.
        Message and data rates may apply. Message frequency varies. Text HELP to 25816 for help. Text STOP to 25816 to cancel.
        For terms: thorne.com/terms-of-use. For privacy policy: thorne.com/privacy-policy.
      </div>
    </div>
    <br/>
    <div v-if="showSubmitButton" class="control">
      <b-button @click="optInToSMS" class="is-black is-large" aria-label="submit" :disabled="!smsSelectionComplete" expanded>Submit</b-button>
    </div>
  </div>
</template>

<script>
import Http from "../../classes/global/Http.js";
import Cleave from "vue-cleave-component";
import Fieldify from "~/components/Fieldify.vue";
require('cleave.js/dist/addons/cleave-phone.i18n');
export default {
  name: "SmsOptInForm",

  components: {Fieldify, Cleave},

  props:{
    email: {type: String, default: ""},
    showSubmitButton: {type: Boolean, default: true},
    mobileInputCols: {default: 12},
    country: {default: ''}
  },

  data(){
    return {
      optInSelection: {
        phoneNumber:"",
        marketingTexts: false,
        transactionalTexts:false
      }
    }
  },

  methods:{

    optInToSMS(){
      this.optInSelection.emailAddress = this.email;
      this.optInSelection.country = this.country
      if(this.optInSelection.phoneNumber.length > 8) {
        Http.post("/sms/subscribe", this.optInSelection)
            .then(result => {
              this.$emit('opted-in');
            })
            .catch(e => {
              this.$buefy.notification.open({
                duration: 2000,
                type: 'is-danger',
                position: 'is-bottom-right',
                message: "Unable to enroll in Thorne SMS.",
                role: "alert"
              })
            });
      }
    },

    emitSelection(){
      this.optInSelection.country = this.country;
      this.$emit('input', this.optInSelection);
    },

    resetPhone(){
      this.optInSelection.phoneNumber = "";
    }
  },

  computed:{
    smsPhoneNumberDisabled(){
      return !(this.optInSelection.transactionalTexts || this.optInSelection.marketingTexts);
    },
    smsSelectionComplete(){
      return (this.optInSelection.phoneNumber.length > 8 && (this.optInSelection.transactionalTexts || this.optInSelection.marketingTexts))
    },
    phoneOptions() {
      return {
        phoneRegionCode: this.country,
        phone: true
      }
    },
  },

  watch: {
    optInSelection: {
      deep: true,
      handler(newVal, oldVal) {
        if(!newVal.marketingTexts && !newVal.transactionalTexts){
          this.resetPhone();
        }
        this.emitSelection();
      }
    },
  }

}
</script>

<style scoped lang="scss">

.sms-opt-in-form{
  .sms-selection-inputs{
    .form-input{
      margin-bottom: 0.75rem;
      font-size: 1rem;
      font-weight: 400;
    }
  }
}
</style>