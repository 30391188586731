<template>
    <div class="affiliate-link-pulldown" :class="{expanded : expanded, minimized : !expanded}">
        <div class="level is-mobile">
            <i v-if="!expanded" class="far fa-angle-double-left level-item expander" @click="setExpanded(true)"></i>
            <div class="level-item" :class="{'is-hidden-mobile' : affiliateLink}">Create & share affiliate link:</div>
            <b-input v-show="!!affiliateLink" :value="affiliateLink"></b-input>
            <button v-if="!affiliateLink" class="button level-item" @click="createAffiliateLink">Create</button>
            <button v-if="affiliateLink" class="button level-item"
                    v-clipboard:copy="affiliateLink"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyFail">Copy</button>
            <i v-if="expanded" class="far fa-times level-item collapser" @click="setExpanded(false)"></i>
        </div>
    </div>
</template>

<script>
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

const STORAGE_KEY = 'aff_link_widget_state';

export default {
    name: 'AffiliateLinkPulldown',

    props: {
        endpoint: {required: true},
        targetUrl: {default: window.location.href},
        pageName: {default: document.title.replace(" | Thorne", "")}
    },

    data() {
        return {
            expanded: localStorage.getItem(STORAGE_KEY) !== 'false',
            affiliateLink: null
        };
    },

    methods: {
        createAffiliateLink() {
            axios.post(this.endpoint, { targetUrl: this.targetUrl, pageName: this.pageName })
                .then(response => {
                    this.affiliateLink = response.data;
                    this.urlError = null;
                }) .catch(error => {
                if(error.response.data.errors) {
                    this.urlError = error.response.data.errors[0].defaultMessage;
                }
                else {
                    this.urlError = "An error occurred trying to generate your link";
                }
            });

        },

        copySuccess() {
            this.$buefy.notification.open({ duration: 2000, type: 'is-success', position: 'is-bottom-right', message: "Link copied to clipboard!", role: "alert" })
        },

        copyFail() {
            this.$buefy.notification.open({ duration: 2000, type: 'is-danger', position: 'is-bottom-right', message: "Link could not be copied.", role: "alert" })
        },

        setExpanded(expanded) {
            this.expanded = expanded;
            localStorage.setItem(STORAGE_KEY, '' + expanded);
        }
    }
}
</script>
